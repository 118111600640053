<template>
  <v-dialog v-model="dialog" width="1080" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Récord del Estudiante</span>
      </v-card-title>
      <v-card-subtitle class="pb-1 pt-1">
        {{ `${student.first_name} ${student.last_name} (${student.code})` }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-container class="mt-4">
          <v-alert
            dense
            outlined
            type="info"
            class="mb-2"
            v-if="lastDiagnosticEvaluation"
          >
            <p class="mb-1">
              <span>Comentario: </span
              >{{ lastDiagnosticEvaluation.suggested_level }}
            </p>
            <span
              >Prueba realizada por:
              <span class="font-weight-bold">
                {{ lastDiagnosticEvaluation.realized_by }} -
                {{ lastDiagnosticEvaluation.diagnostic_date_formatted }}
              </span>
            </span>
          </v-alert>
          <v-row
            v-if="
              student.status == 1 ||
              student.status == 2 ||
              student.status == 'Inscrito'
            "
          >
            <v-col cols="12">
              <v-card outlined>
                <v-data-table
                  :items="courses"
                  :headers="courses_headers"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:[`item.final_score`]="{ item }">
                    <v-chip
                      :color="
                        item.final_score ? 'light-blue lighten-4' : 'gray'
                      "
                      class="text-body-1"
                    >
                      {{ item.final_score || "Nota pendiente" }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="12">
              <h4 class="text-center black--text">
                El estudiante debe ser ubicado en un curso.
              </h4>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RecordStudent",
  data() {
    return {
      dialog: false,
      courses: [],
      programs: [],
      courses_headers: [
        {
          text: "Nombre del curso",
          value: "course",
        },
        {
          text: "Ciclo",
          value: "cycle",
        },
        {
          text: "Nota",
          value: "final_score",
        },
      ],
    };
  },
  props: { student: Object },
  mounted() {
    this.getPrograms();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad
    changeModal: function () {
      this.dialog = !this.dialog;
    },
    getPrograms: function () {
      this.$http.get(this.api + "program/all").then(({ data }) => {
        this.programs = data;
      });
    },
    getCourses: async function () {
      await this.$http
        .post(
          this.api + "course_student/student",
          this.toFormData(this.student)
        )
        .then(({ data }) => {
          this.courses = data;
        });
    },
    closeModal() {
      this.dialog = false;
    },
  },
  watch: {
    student(val) {
      this.getCourses();
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
    lastDiagnosticEvaluation() {
      if (
        this.student?.diagnostic_test_results &&
        this.student?.diagnostic_test_results.length !== 0
      ) {
        const diagnosticOrdered = this.student.diagnostic_test_results.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        return diagnosticOrdered.find(
          (item) => item.suggested_level !== null && item.suggested_level !== ""
        );
      }
      return null;
    },
  },
};
</script>

<style></style>
